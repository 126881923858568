<template>
    <mf-box :empty="!quay" without-inner-paddings :without-header="!isCustomPage">
        <div slot="header">
            Quay Preview
        </div>
        <div class="container quay" v-if="quay">
            <div class="container__row">
                <div class="container__item">
                    <div class="quay__info">
                        <div class="quay__name">
                            {{ quay.name }}
                        </div>
                        <div class="quay__short-name">Short name: {{ quay.shortName }}</div>
                    </div>
                </div>
                <div class="container__item" :class="{ 'container__item_hide-large': false }">
                    <mf-button pattern="primary-outline" class="quay__button" @click="openSidebar">
                        <mf-icon class="button__icon" icon-name="icon-list" height="24" width="24" />
                        Change Quay
                    </mf-button>
                </div>
            </div>
            <div class="container__row">
                <div class="container__item quay__selector">
                    <mf-select
                        placeholder="Add quay to list"
                        :options="possibleQuayLists"
                        label="name"
                        @select="handleAddQuayToList"
                    >
                        <div slot="caret">
                            <mf-icon class="multiselect__select" icon-name="icon-down" />
                        </div>
                    </mf-select>
                </div>
            </div>
        </div>
        <div class="container quay" slot="empty">
            <mf-button pattern="primary-outline" class="quay__button" @click="openSidebar">
                <mf-icon class="button__icon" icon-name="icon-list" height="24" width="24" />
                Change Quay
            </mf-button>
        </div>
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'QuayInfo',
        props: {
            isCustomPage: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapGetters({ quay: 'selectedQuay', quayLists: 'customQuayLists' }),
            possibleQuayLists() {
                return (
                    this.quayLists &&
                    this.quayLists.filter(list => !list.quays.some(quay => quay.URN === this.quay.URN))
                );
            },
        },
        methods: {
            handleAddQuayToList(list) {
                this.$store.dispatch('addItemToCustomList', { list, item: this.quay });
            },
            openSidebar() {
                this.$store.commit('setSidebarValue', 'quay');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/sass/default/layout.scss';

    .quay {
        display: flex;
        flex-direction: column;
        background: $marine-800;
        border-radius: 6px;
    }

    .quay__info {
        padding: $space-16;
        border: 1px solid $marine-500;
        box-sizing: border-box;
        border-radius: 6px;
        position: relative;
    }

    .quay__name {
        font-weight: 600;
        font-size: $font-24;
        line-height: $font-30;
        color: $white;
        margin-bottom: 8px;
    }

    .quay__short-name {
        font-weight: normal;
        font-size: $font-16;
        line-height: $font-18;
        color: $marine-300;
    }

    .quay__selector {
        max-width: 33.33%;
    }

    .quay__button {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $marine-500;
        align-self: stretch;
        height: 100%;
    }

    .container__item_hide-large {
        @include media($lg) {
            display: none;
        }
    }

    .button__icon {
        margin-bottom: $space-12;
    }
</style>
