<template>
    <gmap-map :options="mapOptions" class="quay-location__map" :center="quayPosition.position" ref="map">
        <gmap-polygon class="quay-location__area" ref="polygon" :options="polygonOptions" />
    </gmap-map>
</template>

<script>
    import mapStyles from '@/config/mapStyles';

    export default {
        name: 'QuayPosition',
        props: {
            quayPosition: {
                type: Object,
                required: true,
            },
        },
        async mounted() {
            await this.$gmapApiPromiseLazy();
            // eslint-disable-next-line no-undef
            let bounds = new google.maps.LatLngBounds();
            this.quayPosition.area.coordinates.forEach(coordinate => bounds.extend(coordinate));
            this.$refs.map.fitBounds(bounds);
            this.$refs.map.panToBounds(bounds, 20);
        },
        data() {
            return {
                mapOptions: {
                    styles: mapStyles,
                    disableDefaultUI: true,
                    zoomControl: true,
                },
                polygonOptions: {
                    paths: this.quayPosition.area.coordinates,
                    strokeColor: '#B8EFF1',
                    fillColor: '#495678',
                },
            };
        },
    };
</script>

<style lang="scss" scoped>
    .quay-location__map {
        height: 252px;
        width: 100%;
    }

    .quay-location__area {
        color: #b8eff1;
    }
</style>
