<template>
    <mf-box :empty="!selectedQuay" without-inner-paddings>
        <div slot="header">
            Quay Location
        </div>
        <quay-position v-if="selectedQuay" :quay-position="selectedQuay" :key="selectedQuay.URN" />
        <div slot="empty-text">
            Quay not selected
        </div>
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import QuayPosition from '@/components/quay-components/QuayPosition';

    export default {
        name: 'QuayLocation',
        components: { QuayPosition },
        computed: {
            ...mapGetters(['selectedQuay']),
        },
    };
</script>
