<template>
    <mf-box :empty="!quayId">
        <div slot="header">
            Vessels at Quay
        </div>
        <div class="vessels-at-quay">
            <div class="vessels-at-quay__header">
                Show all visits for:
            </div>
            <div class="vessels-at-quay__selectors">
                <mf-select
                    class="vessels-at-quay__selector"
                    v-model="period"
                    :options="availablePeriods"
                    :show-labels="false"
                >
                    <div slot="caret">
                        <mf-icon class="multiselect__select" icon-name="icon-down" />
                    </div>
                </mf-select>
                <mf-select
                    class="vessels-at-quay__selector"
                    v-model="time"
                    :options="availableTimes"
                    :show-labels="false"
                >
                    <div slot="caret">
                        <mf-icon class="multiselect__select" icon-name="icon-down" />
                    </div>
                </mf-select>
            </div>
            <template v-if="isVisitsInfo">
                <table class="vessels-at-quay__table">
                    <tr class="vessels-at-quay__labels">
                        <th class="vessels-at-quay__th"></th>
                        <th class="vessels-at-quay__th" v-for="infoKey in infoKeys" :key="infoKey">
                            <div class="vessels-at-quay__label">
                                {{ infoKey }}
                            </div>
                        </th>
                    </tr>
                    <mf-divider />
                    <template v-for="(visitInfo, index) in visitsInfo">
                        <tr
                            class="vessels-at-quay__line"
                            :key="visitInfo.mmsi"
                            :class="index % 2 && 'vessels-at-quay__line_light'"
                        >
                            <td class="vessels-at-quay__th">
                                <div class="vessels-at-quay__title">{{ visitInfo.name }}</div>
                            </td>
                            <td class="vessels-at-quay__th" v-for="infoKey in infoKeys" :key="`value-${infoKey}`">
                                <div class="vessels-at-quay__value">
                                    <div>
                                        {{ visitInfo[`numberOf${infoKey}`] }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <mf-divider :key="`divider-${visitInfo.mmsi}`" />
                    </template>
                </table>
            </template>
            <div v-else class="vessels-at-quay__empty">
                No visits in selected time frame.
            </div>
        </div>
        <div slot="empty-text">
            Quay not selected
        </div>
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { debounce } from 'lodash';

    export default {
        name: 'VesselsAtQuay',
        data() {
            return {
                period: 'This',
                periods: ['Last', 'This', 'Next'],
                time: 'Week',
                times: ['Day', 'Week', 'Month', 'Year'],
                infoKeys: ['Current', 'Planned', 'Passed', 'Unknown'],
            };
        },
        watch: {
            quayId: {
                handler(val) {
                    val && this.fetchData();
                },
                immediate: true,
            },
            time(val) {
                val && this.fetchData();
            },
            period(val) {
                val && this.fetchData();
            },
        },
        computed: {
            ...mapGetters({ quayId: 'selectedQuayId', visitsInfo: 'selectedQuayVisitsInfo' }),
            availablePeriods() {
                return this.periods.filter(period => period !== this.period);
            },
            availableTimes() {
                return this.times.filter(period => period !== this.time);
            },
            timeState() {
                let timeState = 0;
                this.period === 'Last' && (timeState = -1);
                this.period === 'Next' && (timeState = 1);
                return timeState;
            },
            timeRange() {
                const now = new Date();
                const currentDay = now.getDay();
                const currentDate = now.getDate();
                const currentMonth = now.getMonth();
                const currentYear = now.getFullYear();
                let firstDay = new Date(now.setHours(0, 0, 0));
                let lastDay = new Date(now.setHours(23, 59, 59));
                if (this.time === 'Day') {
                    firstDay = new Date(firstDay.setDate(currentDate + this.timeState));
                    lastDay = new Date(lastDay.setDate(currentDate + this.timeState));
                } else if (this.time === 'Week') {
                    const startDayOfWeek = currentDate - currentDay + (currentDay === 0 ? -6 : 1);
                    firstDay = new Date(firstDay.setDate(startDayOfWeek + this.timeState * 7));
                    lastDay = new Date(lastDay.setDate(startDayOfWeek + 6 + this.timeState * 7));
                } else if (this.time === 'Month') {
                    firstDay = new Date(firstDay.setMonth(currentMonth + this.timeState, 1));
                    lastDay = new Date(lastDay.setMonth(currentMonth + this.timeState + 1, 0));
                } else if (this.time === 'Year') {
                    firstDay = new Date(firstDay.setFullYear(currentYear + this.timeState, 0, 1));
                    lastDay = new Date(lastDay.setFullYear(currentYear + this.timeState + 1, 0, 0));
                }
                return { from: new Date(firstDay).toISOString(), to: new Date(lastDay).toISOString() };
            },
            isVisitsInfo() {
                return this.visitsInfo && Object.keys(this.visitsInfo).length;
            },
        },
        methods: {
            fetchData: debounce(function() {
                const params = {
                    from_time: this.timeRange.from,
                    to_time: this.timeRange.to,
                    location: this.quayId,
                    event_definition: 'VESSEL_AT_BERTH',
                };
                this.$store.dispatch('getQuayVisitsInfo', params);
            }, 500),
        },
    };
</script>

<style lang="scss" scoped>
    .vessels-at-quay__header {
        font-weight: bold;
        font-size: $font-16;
        line-height: $font-24;
        color: $white;
    }

    .vessels-at-quay__selectors {
        display: flex;
        margin: $space-8 0;
    }

    .vessels-at-quay__selector {
        max-width: 183px;
        &:not(:last-child) {
            margin-right: 8px;
        }
    }

    .vessels-at-quay__empty {
        text-align: left;
        font-size: $font-16;
        line-height: $font-24;
        color: $marine-400;
    }

    .vessels-at-quay__table {
        width: 100%;
        margin-top: $space-40;
        padding-bottom: $space-4;
        position: relative;
        th,
        tr,
        td {
            padding: 0;
        }
    }

    .vessels-at-quay__title {
        font-weight: bold;
        font-size: $font-13;
        line-height: $font-18;
        display: flex;
        align-items: center;
        color: $white;
        opacity: 0.8;
        min-width: 170px;
        padding: $space-8;
    }

    .vessels-at-quay__value {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    .vessels-at-quay__line {
        display: flex;
    }

    .vessels-at-quay__line_light {
        background: $marine-600;
    }

    .vessels-at-quay__labels {
        display: flex;
    }

    .vessels-at-quay__th {
        display: flex;
        align-items: stretch;
        min-width: 120px;
        color: $white;
        position: relative;
    }

    .vessels-at-quay__th:first-child {
        min-width: 170px;
        flex: 0;
    }

    .vessels-at-quay__label {
        font-size: $font-12;
        line-height: $font-18;
        border: 1px solid $marine-500;
        padding: 0 5px;
        border-top: transparent;
        height: 16px;
        flex: 1 1 auto;
    }
</style>
